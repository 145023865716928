import '../styles/typography.scss';

import { Typography } from '@mui/material';
import React from 'react';

export const TextComponent = ( props ) =>
{
  const customize = {
    color:props.color,
    fontSize:props.size,
    fontWeight:props.weight,
    height:props.height,
    marginTop:props.marginTop,
    marginBottom:props.marginBottom
  };

  return(
    <>
      <Typography className={props.className} variant={props.variant || 'subtitle1'} sx={customize}>{props.children}</Typography>

    </>
  );
};