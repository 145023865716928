import '../styles/inputfield.scss';
import '../styles/customer-profile.scss';
import '../styles/swal.scss';

const axios = require( 'axios' );

import { gql, useMutation, useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useEffect,useState } from 'react';
const bubbIcon = 'https://public-bubble.s3.us-west-2.amazonaws.com/images/bubb.svg';

import { useAuth0 } from '@auth0/auth0-react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { navigate } from 'gatsby';
import Swal from 'sweetalert2';

import ContactCustomer from '../components/contact';
import Layout from '../components/layout';
import Maps from '../components/maps-customer';
import ProfilePhoto from '../components/profile-photo';
import Quotecard from '../components/quotecard';
import { TextComponent } from '../components/TextComponent';
import { getZipCode } from '../helpers/address';

const UPDATE_CUSTOMER = gql`
mutation MyMutation($_eq: Int!, $address: String = "", $email: String = "", $phone: String = "",$profile_url: String = "") {
  update_agentportalcustomers(where: {id: {_eq: $_eq}}, _set: {address: $address, email: $email, phone: $phone ,profile_url:$profile_url}) {
    returning {
      address
      id
      phone
      email
      profile_url
    }
  }
}`;

const PROD_UPDATE_CUSTOMER = gql`
mutation MyMutation($_eq: Int!, $address: String = "", $email: String = "", $phone: String = "",$profile_url: String = "") {
  update_agentportalcustomers: prod_update_agentportalcustomers(where: {id: {_eq: $_eq}}, _set: {address: $address, email: $email, phone: $phone,profile_url:$profile_url}) {
    returning {
      address
      id
      phone
      email
      profile_url
    }
  }
}`;

const GET_CUSTOMER = gql `query MyQuery($customer_id: Int!) {
  agentportalcustomers(where: {id: {_eq: $customer_id}}) {
    address
    email
    first_name
    full_name
    home_premium
    id
    insurance_status
    client_id
    insurance_types
    last_name
    phone
    phone_no
    profile_url
    viewed
    zip
  }
}
`;

const PROD_GET_CUSTOMER = gql `query MyQuery($customer_id: Int!) {
  agentportalcustomers: prod_agentportalcustomers(where: {id: {_eq: $customer_id}}) {
    address
    email
    first_name
    full_name
    home_premium
    life_premium
    id
    insurance_status
    client_id
    insurance_types
    last_name
    phone
    phone_no
    profile_url
    viewed
    zip
  }
}
`;

const CssTextField = styled( TextField )( {
  '& label.Mui-focused': {
    color: '#646464'
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#DAE7EC'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#DAE7EC'
    },
    '&:hover fieldset': {
      borderColor: '#DAE7EC'
    },
    '&.Mui-focused fieldset': {
      borderColor: '#DAE7EC'
    }
  }
} );


function Customers() {
  let customerId;


  if ( typeof window !== 'undefined' ) {
    customerId = localStorage.getItem( 'id' );

    const params = new URLSearchParams( location.search );
    const customer2 = params.get( 'customer-id' );
  }
  const { data, loading, refetch } = useQuery( process.env.GATSBY_ENV  == 'PROD' ? PROD_GET_CUSTOMER : GET_CUSTOMER, {
    variables: {
      customer_id: customerId
    }
  } ) || { data: {} };

  const [customer, setCustomer] = useState( {} );
  const apiUrl = `${process.env.GATSBY_NEST_APP_URL}states`;

  useEffect( () => {
    if( !loading && data ){
      setCustomer( data?.agentportalcustomers[0] || {} );
      setEmail( customer?.email );
    }
  }, [data] );

  const [updateCustomer, { updateData, updateLoading, updatError }] = useMutation( process.env.GATSBY_ENV == 'PROD' ? PROD_UPDATE_CUSTOMER : UPDATE_CUSTOMER );
  useEffect( () => {
    setEmail( customer?.email );
    setPhone( customer?.phone );
    setAddress( customer?.address );
    setProfilePic( customer?.profile_url );
  }, [customer] );

  const { isLoading: authLoading, isAuthenticated } = useAuth0();
  useEffect( () => {
    if( !( authLoading || isAuthenticated ) ){
      navigate( '/login' );
    }
  }, [authLoading] );

  const [email, setEmail] = useState( customer?.email || '' );
  const [phone, setPhone] = useState( customer?.phone || '' );
  const [address, setAddress] = useState( customer?.address || '' );
  const [profilePic, setProfilePic] = useState( customer?.profile_url || '' );
  const [invalidAddress, setInvalidAddress] = useState( false );
  const [ enabledStates, setEnabledStates ] = useState( [] );


  const [quotes, setQuotes] = useState( [] );

  function emailChange( e ){
    const email = e.target.value;
    setEmail( email );
  }

  function phoneChange( e ){
    const phone = e.target.value;
    setPhone( phone );
  }

  function addressChange( value ){
    debugger;
    const address = value;
    setAddress( address );
  }


  useEffect( () => {
    console.log( customer.client_id, 'clientId' );
    async function getClientData(){
      const { client_id } = customer;
      if( client_id ){
        const url = process.env.GATSBY_API_BASE_URL + '/client-data/' + client_id;

        const response = await fetch( url );
        const data = await response.json();

        setQuotes( data );
      }
    }

    getClientData();
  }, [customer] );


  function sendForm(){
    updateCustomer( {
      variables: {
        _eq: customerId,
        address: address,
        email: email,
        phone: phone,
        profile_url:profilePic || ''
      }
    } ).then( ( res ) => {
      console.log( res, 'response' );
      Swal.fire( {
        text: 'Customer profile updated successfully',
        confirmButtonColor: '#f46904',
        confirmButtonText: 'OK',
        customClass: 'swal-wide'
      } ); } );


    refetch();
  }

  const updateProfilePicUrl = ( newValue ) => {
    setProfilePic( newValue );
  };

  const inValid = async value  =>
  {
    setInvalidAddress( value );
    debugger;


  };


  useEffect( () => {
    axios.get( apiUrl )
      .then( response => {
        // Handle the data
        console.log( 'API response:', response.data.home );
        setEnabledStates( response.data.home );
      } )
      .catch( error => {
        // Handle errors
        console.error( 'Error fetching data:', error );
      } );
  }, [] );


  return(
    <>
      <Layout>
        <Box sx={{ width: '100%' }} className="custprofsecboxout">
          <Grid item xs={12}>
            <div className="custprofsecbox">
              <Typography variant="h2" className="quotes">
                Customer Profile
              </Typography>

              <Typography variant="p" className="get-quotes-under">
                {/* View and Edit your customer’s profile below */}
              </Typography>

              <div className="row">
                <Grid item xs={12} lg={12}>
                  <div className="row">
                    <Grid item xs={12} sm={12} md={4} lg={4} display="flex" flexDirection="column" alignItems="center" className="profcontdetail" >

                      <ProfilePhoto width={0} src={profilePic || bubbIcon} updateProfilePicUrl={updateProfilePicUrl}/>

                      <ContactCustomer data={customer}/>

                    </Grid>


                    <Grid item xs={12} sm={12} md={5} lg={5} className="mobspacebox">
                      <div className="row row-customer">

                        <Grid item xs={12} sm={12} md={12} lg={12} className="mobspacebox">
                          <CssTextField sx={{ width:'100%' }}
                            className="input-contact"
                            id="outlined-basic"
                            value={email}
                            disabled
                            onChange={emailChange}
                            label={
                              <>
                                Email <sup>*</sup>
                              </>
                            }
                            variant="filled"
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} className="mobspacebox">
                          <CssTextField sx={{ width:'100%' }}
                            className="input-contact"
                            id="outlined-basic"
                            value={phone}
                            key={2}
                            disabled
                            onChange={phoneChange}
                            label={
                              <>
                                Mobile <sup>*</sup>
                              </>
                            }
                            variant="filled"
                          />
                        </Grid>

                        {/* <Grid item xs={12} sm={12} md={12} lg={12} className="mobspacebox">
                          <CssTextField sx={{ width:'100%' }}
                            className="input-contact"
                            id="outlined-basic"
                            value={address}
                            key={3}
                            onChange={addressChange}
                            label={
                              <>
                                Address <sup>*</sup>
                              </>
                            }
                            variant="filled"
                          />
                        </Grid> */}

                        <div className="input-contact">
                          <div className="MuiOutlinedInput-root">

                            <Maps
                              type= 'text'
                              className="input-contact"
                              id="outlined-basic"
                              getAddress={addressChange}
                              variant="filled"
                              disabled
                              addressProp={address}
                              placeholder={'Address'}
                              insertLabel={true}
                              disableButton={inValid}
                              enabledStates={enabledStates}

                              required
                            />
                          </div>
                        </div>

                      </div>

                    </Grid>
                  </div>
                </Grid>
              </div>

              <div className="row">
                <Grid item xs={12}>
                  <button disabled={invalidAddress} onClick={sendForm} className='orange-button round-button button-lg btnspace' style={{ cursor:invalidAddress ? 'not-allowed' : 'pointer' }}>
                    Save and update
                  </button>
                </Grid>
              </div>

              {/* <div className="row">
                <Typography variant="h2" className="quotes mb-30">
                  Customer Quotes
                </Typography>

                <Grid item xs={12} lg={12} sm={6} md={6} className="quoteboxbt"  display="flex"  sx={{ '@media(max-width:767px)':{ flexWrap:'wrap' } , '@media(max-width:900px)':{ flexWrap:'wrap' } }}>
                </Grid>
              </div> */}
            </div>
          </Grid>
        </Box>


        <Box sx={{ width: '100%' }} className="">

          {/* <Grid container spacing={2}>
            <Grid item xs={12} sm={12} lg={12}>


              <Box p={2}>
                <p className="quotes">Customer Profile</p>

                <p className="get-quotes-under">View and Edit your customer’s profile below</p>
              </Box>
            </Grid> */}

          {/* <Grid container spacing={2}>
              <Grid item xs={12} sm={3} ml={-3}>
                <Box p={2} my={5} ml={15}>
                  <ProfilePhoto src={ProfilePic}/>

                  <ContactCustomer data={customer}/>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box p={2} ml={2}>
                  <InputField
                    className='customer-input'
                    placeholder="Type your e-mail here"
                    onChange={emailChange}
                    multi={false}
                    value={email}
                    label={
                      <>
                      E-mail <sup></sup>
                      </>
                    }
                    variant="filled"
                  />

                  <InputField
                    className='customer-input'
                    onChange={phoneChange}
                    placeholder="+1(xxx)xxx-xxxx"
                    multi={false}
                    value={phone}
                    label={
                      <>
                      Mobile <sup></sup>
                      </>
                    }
                    variant="filled"
                  />

                  <InputField
                    className='customer-input'
                    onChange={addressChange}
                    placeholder="Type your address here"
                    multi={false}
                    value={address}
                    label={
                      <>
                      Address<sup></sup>
                      </>
                    }
                    variant="filled"
                  />
                </Box>
              </Grid>
            </Grid> */}

          { quotes.length ?
            <Grid cintainer  spacing={2} ml={2}>

              <Grid item xs={12} sm={6}>
                <Box p={2}>
                  <TextComponent color="#646464" size="32px" weight="bold" >Customer Quotes</TextComponent>
                </Box>
              </Grid>


              <Grid item xs={12} lg={12} sm={6} mb={12} className="quoteboxbt"  display="flex"  sx={{ '@media(max-width:767px)':{ flexWrap:'wrap' } }}>

                {
                  quotes.map( ( data, i ) =>
                    ( <Quotecard
                      key={i}
                      data={data}
                      color="#0d4d90">
                    </Quotecard> ) )
                }

                {/*
                <Quotecard
                  quote="80"
                  clientId={customer.client_id}
                  insureType="Home Insurance"
                  carrier="Stillwater"
                  color="#00a12f">
                </Quotecard> */}

              </Grid>
            </Grid>
            : ''
          }       </Box>


      </Layout>
    </>
  );
}

export default  Customers;
