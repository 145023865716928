import Grid from '@mui/material/Unstable_Grid2';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import Button from './Button';
import { TextComponent } from './TextComponent';


function ContactCustomer( { data } ) {

  const [display, setDisplay] = useState( '' );

  useEffect( () => {
    const comps = data?.address?.split( ',' )?.reverse();
    if( comps ){
      setDisplay( comps[2] + ',' + comps[1] );
    }

  }, [data] );

  function call(){
    window.open( `tel:${data.phone}`, '_blank' );
  }

  function email(){
    window.open( `mailto:${data.email}`, '_blank' );
  }

  return(
    <>
      <Grid xs={12} lg={12} display="flex" flexDirection="column" justifyContent="center" alignItems="center" >
        <TextComponent className="custnametxt">
          {data?.full_name || ''}
        </TextComponent>

        <TextComponent className="custaddtxt">
          {display || ''}
        </TextComponent>
      </Grid>

      {/* <Grid xs={6} lg={12} display="flex" ml={-5} className="contactbtn" >
        <Button width="100px" height="30px" borders="50px" fontSize="10px" color="#ff7007" onClick={email} background="white" border="1px solid #ff7007">Email</Button>

        <Button width="100px" height="30px" borders="50px" fontSize="10px" color="#ff7007" onClick={call} background="white" border="1px solid #ff7007">Call</Button>
      </Grid> */}
    </>
  );
}

export default ContactCustomer;
