import '../styles/address.scss';
import '../styles/search.scss';

import React, { useEffect, useRef,useState } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { geocodeByAddress } from 'react-places-autocomplete';

import { getZipCode } from '../helpers/address';


function Maps( { placeholder, getAddress = console.log,addressProp,disableButton,enabledStates } ) {

  const [ address, setAddress ] = useState( addressProp || '' );
  const [ invalidAddress, setInvalidAddress ] = useState(  '' );

  const inputAddressRef = useRef();
  const handleSelect = async value => {
    await checkAddress( value );
  };

  async function checkAddress( value )
  {
    debugger;
    const results = await geocodeByAddress( value );
    console.log( results, 'results' );
    const returnedAddress = getZipCode( results );
    let addressInvalid = isObjectEmpty( returnedAddress );
    console.log( 'checkAddress',addressInvalid );
    console.log( 'enabledStates',enabledStates );
    const validState = enabledStates.includes( returnedAddress.state );
    if( addressInvalid || !validState )
    {
      let errorText = !validState ? 'Currently we serve in ' +  enabledStates.join( ', ' ) : 'The entered home address is not in correct format. Please re-enter to continue';
      setAddress( '' );
      getAddress( value );
      setInvalidAddress( errorText );
      disableButton( true );
    }
    else{
      setAddress( value );
      getAddress( value );
      setInvalidAddress( '' );
      disableButton( false );
    }
  }

  function isObjectEmpty( obj ) {
    for ( const key in obj ) {
      if ( Object.hasOwnProperty.call( obj, key ) && ( obj[key] === null || obj[key] === undefined || obj[key] === '' ) ) {
        return true; // Found an empty value
      }
    }
    return false; // No empty values found
  }


  const handleAddress = async value => {
    debugger;
    setAddress( value );
    setInvalidAddress( 'Enter Valid Address' );
    disableButton( true );
  };

  useEffect( () => {
    setAddress( addressProp );
  }, [addressProp] );

  return(
    <>
      <PlacesAutocomplete value={address}                      onChange={handleAddress} onSelect={handleSelect} >
        {( { getInputProps, suggestions, getSuggestionItemProps, loading } ) => (
          <div>
            <form autoComplete="off" style={{ marginBottom:'0px' }}>
              <label className="mui-label">Address <sup>*</sup></label>

              <input
                autoComplete="off"
                id="address-input"
                ref={inputAddressRef}
                {...getInputProps( {
                  placeholder:  placeholder || ''
                } )}
              />

              {invalidAddress && <p style={ { marginBottom:'0px',position: 'relative',color:'red' ,fontSize:'12px',top:'12px'  } }>{invalidAddress}</p>}


              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}

                {suggestions.map( ( suggestion, idx ) => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  // inline style for demonstration purpose
                  //  console.log(suggestion, "key");
                  const style = suggestion.active
                    ? { backgroundColor: '#f5fafc', cursor: 'pointer', borderRadius: '4px' }
                    : { backgroundColor: '#fff', cursor: 'pointer', paddingBottom: '8px' };
                  return (
                    <div key={suggestion.placeId + idx}
                      {...getSuggestionItemProps( suggestion, {
                        className,
                        style,
                        key: suggestion.placeId + idx
                      } )} className="dropdown-bottom-border"
                    >
                      <div >{suggestion.description}</div>
                    </div>
                  );
                } )}
              </div>
            </form>
          </div>
        )}
      </PlacesAutocomplete>

    </>
  );
}

export default Maps;
