import '../styles/common.scss';
import '../styles/quote-card.scss';

import Card from '@mui/material/Card';
import React from 'react';

import homeIcon from '../assets/images/home.svg';

function Quotecard( { data } ) {
  const colors =  {
    Premium: { color: '#0d4d90', background: 'rgba(13, 77, 144, 0.1)' },
    Primary: { color: '#5e5e5e', background: 'rgba(207, 207, 207, 0.3)' },
    Popular:{ color: '#9375ff', background: 'rgba(147, 117, 255, 0.1)' }
  };
  return (
    <div className='d-flex flex-column'>
      <div className='insure-type'>
        <div className='d-flex align-items-center'>
          <img src={homeIcon} alt="" className="icon" ></img>

          <p className="insure-type">{data.insureType}</p>
        </div>

        {/* <div className='right'>
          {
            returnBox(  'lead' )
          }
        </div> */}
      </div>

      <Card sx={{   'width': '384px', 'minHeight': '340px' ,'border':'1px solid #DAE7EC','padding':'20px', 'margin':'10px','borderRadius':'10px','@media(max-width:767px)':{ width:'100%' , minWidth:'100%' }, '@media(max-width:900px)':{ minWidth:'100%', width:'100%' } }}>
        <div className="quote-header">
          { data.tag &&
               <p className='tag'style={{
                 color: colors[data.tag].color,
                 backgroundColor: colors[data.tag].background }}
               >
                 {data.tag}
               </p>
          }

          <img style={{ width: '80px' }} src={data.logo} alt=""></img>

          <p className='carrier'>{data.company_name}</p>


          <div className='quote'>
            ${data.monthly_quote}

            <div>
              <span className="month">/mo
              </span>
            </div>
          </div>
        </div>

        <div className="quote-body">
          <div className='policy-details'>
            <p className='quote-text'>Area</p>

            <p className='quote-value'>{data.livingArea} sq ft</p>
          </div>

          <div className='policy-details'>
            <p className='quote-text'>Coverage</p>

            <p className='quote-value'>{data.coverage}</p>
          </div>

          <div className='policy-details'>
            <p className='quote-text'>Desired Term</p>

            <p className='quote-value'>1 year</p>
          </div>

          <div className='policy-details'>
            <p className='quote-text'>Year Built</p>

            <p className='quote-value'>{data.year_built}</p>
          </div>

          <div className='policy-details'>
            <p className='quote-text'>Property address</p>

            <p className='quote-value'>{data.propertyAddress}</p>
          </div>

        </div>

        {/* <div className="quote-footer">
          <Button route="/my-profile" width="125px" height="35px" borders="50px" fontSize="10px" color="#fff" background="#ff7007" border="1px solid #ff7007">Learn More</Button>
        </div> */}

        <br/>
      </Card>
    </div>
  );
}

export default Quotecard;
