import '../styles/profile.scss';

import Button from '@mui/material/Button';
import React, { useEffect,useRef,useState } from 'react';
import ReactRoundedImage from 'react-rounded-image';
import Swal from 'sweetalert2';


function ProfilePhoto( {
  src,
  getFile,
  width,
  user_id,
  updateProfilePicUrl,
  firePopUp } ) {

  const fileInput = useRef();
  const [url, setUrl] = useState( src );
  const [anchorEl, setAnchorEl] = React.useState( null );

  useEffect( () => {
    setUrl( src );
  }, [src] );

  const onImageChange = ( event ) => {
    debugger;
    const image = event.target.files[0];
    if ( !image ) {
      SwalPopUp( 'Please select a file.' );
      return false;
    }
    if ( !image.name.match( /\.(jpg|jpeg|png|gif)$/ ) ) {
      SwalPopUp( 'Please upload an image.' );
      return false;
    }

    const buffer = URL.createObjectURL( event.target.files[0] );
    if ( event.target.files && event.target.files[0] ) {
      setUrl( buffer );
      submit();
    }

    if( getFile ){
      getFile( event.target.files[0] );
    }
  };


  function SwalPopUp( text ){
    setAnchorEl( null );
    Swal.fire( {
      imageUrl: 'https://cdn.getmybubble.com/images/corporate-sites/images/education2.png',
      imageHeight: 151,
      imageWidth: 125,
      title: '',
      text,
      type: 'warning'
    } );

  }

  function submit(){
    let file = fileInput.current.files[0];
    let formData = new FormData();

    const filename = ( user_id || '' ) + '_' + file.name.replace( / /g, '_' );
    formData.append(
      'image', file, filename
    );

    fetch( 'https://app.getmybubble.co/api/upload', {
      method: 'POST',
      body: formData
    } ).then( res => res.json() )
      .then( ( { url } ) => { updateProfilePicUrl && updateProfilePicUrl( url ); } )
      .catch( console );
  }

  return(
    <>
      <div className="image-border">
        <ReactRoundedImage
          image={url}
          align="center"
          borderRadius="50%"
          roundedSize="0"
          imageHeight={width || 100}
          imageWidth={width || 100}/>

        {/* <Link to="/my-profile/" className="editimg">
          <img src="https://public-bubble.s3.us-west-2.amazonaws.com/images/Group.svg" alt="Edit"/>
        </Link> */}
      </div>

      <div className="image-upload-container">

        <input
          onChange={onImageChange}
          accept="image/png, image/gif, image/jpeg"
          // style={{ display: 'none' }}
          id="raised-button-file"
          multiple
          ref={fileInput}
          type="file"
        />

        <label htmlFor="raised-button-file">
          <Button variant="raised" component="span" style={{ color: 'red', fontSize: '10px', fontFamily: 'Arial', marginBottom: '-6px',cursor:'pointer',position:'relative',left:'10px' }}>
            Change/Upload here
          </Button>
        </label>
      </div>

    </>
  );
}

export default ProfilePhoto;
